<template>
    <el-row class="item">
            <el-col :span="12" class="title">
                    商品信息
            </el-col>
             <el-col :span="4" class="text-center">
                    商品原单价  (元)
            </el-col>
            <el-col :span="3" class="text-center" v-if="back">
                    可退数量
            </el-col>
            <el-col :span="3" class="text-center"  v-else>
                    数量
            </el-col>
            <el-col :span="3" class="text-center">
                    金额  (元)
            </el-col>
            <el-col :span="3" class="text-center">
                    实付金额 (元)
            </el-col>

    </el-row>
</template>

<script>
  export default {
    name:"OrderTiltle",
    data(){
        return{
          
        }
    },
     props:{
        back:{
            type:Boolean
        }
    },
    methods:{
      
    }
  }
</script>

<style lang="less" scoped>
@import '~style/index.less';
 .item {
         width: 100%;
          display: flex;
          align-items: center;
          background: #F0F0F0;
          height: 50px;
          color: #666;
          font-size: 13px;
          line-height: 18px;
          .title {
                  padding-left: 128px;
          }
      }
</style>
